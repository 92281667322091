import * as browser from "./browser";
import * as server from "./server";

/**
 *
 * Display the subscription functionality for registering the service worker and allowing the users to subscribe
 */

// Once the service worker is registered set the initial state
export function initialiseState(serviceWorkerRegistration, vapid, client, token, pushSubscriptorId) {
  // Check if push messaging is supported
  if (!('PushManager' in window)) {
    return;
  }

  // We need the service worker registration to check for a subscription
  navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
    // Do we already have a push message subscription?
    serviceWorkerRegistration.update();
    serviceWorkerRegistration.pushManager.getSubscription()
      .then(function (subscription) {
        // Enable any UI which subscribes / unsubscribes from
        // push messages.
        if (!subscription) {
          subscribe(vapid, client, token, pushSubscriptorId);
          return;
        }

        // Keep your server in sync with the latest subscription
        return sendSubscriptionToServer(subscription, client, token, pushSubscriptorId);
      })
      .catch(function (err) {
      });
  }).catch(function (err) {
  });
}


function endpointWorkaround(pushSubscription) {
  // Make sure we only mess with GCM
  if (pushSubscription.endpoint.indexOf('https://android.googleapis.com/gcm/send') !== 0) {
    return pushSubscription.endpoint;
  }

  var mergedEndpoint = pushSubscription.endpoint;
  // Chrome 42 + 43 will not have the subscriptionId attached
  // to the endpoint.
  if (pushSubscription.subscriptionId &&
    pushSubscription.endpoint.indexOf(pushSubscription.subscriptionId) === -1) {
    // Handle version 42 where you have separate subId and Endpoint
    mergedEndpoint = pushSubscription.endpoint + '/' +
      pushSubscription.subscriptionId;
  }
  return mergedEndpoint;
}

function getParameterByName(name) {
  var url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function addParameterToUrlIfExists(parameterId, paramSeparator) {
  var param = getParameterByName(parameterId);
  if (param != null && param.length > 0) {
    return paramSeparator + parameterId + '=' + param;
  }

  return '';
}

function sendSubscriptionToServer(subscription, clientId, token, pushSubscriptorId) {
  // Return a new promise.
  var mergedEndpoint = endpointWorkaround(subscription);
  var subscriptionData = JSON.parse(JSON.stringify(subscription));

  var data = "clientId=" + clientId + "&id=" + pushSubscriptorId + '&endpoint=' + mergedEndpoint + '&p256dh=' + subscriptionData.keys.p256dh
    + "&auth=" + subscriptionData.keys.auth + "&device=" + browser._wpnGetDeviceType() + "&tk=" + token;

  return fetch(server.wpnBaseUrl() + '/push-register/update-subscription', {
    method: 'POST',
    mode: 'cors', //To avoid opaque and empty responses
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    body: data
  }).then(function (response) {
    return response.json();
  }).catch(function (err) {
    console.error(err);
  });

}

function subscribe(vapid, clientId, token, pushSubscriptorId) {
  navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
    var subscriptionOpts = {userVisibleOnly: true};
    subscriptionOpts.applicationServerKey = urlBase64ToUint8Array(vapid);

    serviceWorkerRegistration.pushManager.subscribe(subscriptionOpts)
      .then(function (subscription) {
        return sendSubscriptionToServer(subscription, clientId, token, pushSubscriptorId);
      })
      .catch(function (e) {
        if (Notification.permission === 'denied') {
          console.log('denied permission'); //TODO: Console logs
        } else {
          // A problem occurred with the subscription, this can
          // often be down to an issue or lack of the gcm_sender_id
          // and / or gcm_user_visible_only
          console.log(e);
        }
      });
  });
}

// This function is needed because Chrome doesn't accept a base64 encoded string
// as value for applicationServerKey in pushManager.subscribe yet
// https://bugs.chromium.org/p/chromium/issues/detail?id=802280
function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}