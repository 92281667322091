import * as server from "./server";
import * as browser from "./browser";

const WAPP_BUTTON_CLICK_EVENT_TYPE = 20;
const _WPN_WA_BACKGROUND_COLOR = '#1FAF38';
const _WPN_BTN_CLASS = 'wpn-wa-btn-container';
const _WPN_BUY_BTN_SELECTOR = '[data-component="product.add-to-cart"]';

export function _wpnEnsureWhatsappButton() {
  const MAX_MOBILE_VIEWPORT_WIDTH = 767;
  const isMobile = window.visualViewport.width <= MAX_MOBILE_VIEWPORT_WIDTH;
  if(_wpnHasWhatsappButtonInstalled() && isMobile){
    if(typeof $ === 'function'){ //TODO Might be changed later, for now jQuery is needed
      _wpnCreateWhatsappButtonAndListener();
    }
  }
}

function _wpnCreateWhatsappButtonAndListener(){
  const btn = document.querySelector(_WPN_BUY_BTN_SELECTOR);

  if(btn !== null) {
    if(document.querySelector('.' + _WPN_BTN_CLASS) === null) {
      const styles = window.getComputedStyle(btn);

      let cssText = styles.cssText;
      if (!cssText) {
        cssText = Array.from(styles).reduce((str, property) => {
          return `${str}${property}:${styles.getPropertyValue(property)};`;
        }, '');
      }
      const wBtnContainer = document.createElement('div');
      wBtnContainer.classList.add(_WPN_BTN_CLASS);

      const wBtnSeparator = document.createElement('div');
      wBtnSeparator.style.textAlign = 'center';
      wBtnSeparator.style.marginTop = '5px';
      wBtnSeparator.innerHTML = '<svg width="197" height="21" viewBox="0 0 197 21" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path d="M0 10.5H84.5" stroke="#DBDBDB"/>' +
        '<path d="M98.4991 15.098C97.5937 15.098 96.7677 14.888 96.0211 14.468C95.2744 14.0387 94.6817 13.446 94.2431 12.69C93.8137 11.9247 93.5991 11.066 93.5991 10.114C93.5991 9.162 93.8137 8.308 94.2431 7.552C94.6817 6.78667 95.2744 6.194 96.0211 5.774C96.7677 5.34467 97.5937 5.13 98.4991 5.13C99.4137 5.13 100.244 5.34467 100.991 5.774C101.738 6.194 102.326 6.782 102.755 7.538C103.184 8.294 103.399 9.15267 103.399 10.114C103.399 11.0753 103.184 11.934 102.755 12.69C102.326 13.446 101.738 14.0387 100.991 14.468C100.244 14.888 99.4137 15.098 98.4991 15.098ZM98.4991 13.992C99.1804 13.992 99.7917 13.8333 100.333 13.516C100.884 13.1987 101.313 12.746 101.621 12.158C101.938 11.57 102.097 10.8887 102.097 10.114C102.097 9.33 101.938 8.64867 101.621 8.07C101.313 7.482 100.888 7.02933 100.347 6.712C99.8057 6.39467 99.1897 6.236 98.4991 6.236C97.8084 6.236 97.1924 6.39467 96.6511 6.712C96.1097 7.02933 95.6804 7.482 95.3631 8.07C95.0551 8.64867 94.9011 9.33 94.9011 10.114C94.9011 10.8887 95.0551 11.57 95.3631 12.158C95.6804 12.746 96.1097 13.1987 96.6511 13.516C97.2017 13.8333 97.8177 13.992 98.4991 13.992Z" fill="#808080"/>' +
        '<path d="M112.5 10.5H197" stroke="#DBDBDB"/>' +
        '</svg>'

      const wBtn = document.createElement('a');
      wBtn.addEventListener('click', _wpnRedirectBtnToWhatsapp)
      wBtn.style.cssText = cssText;
      //TODO This can be improved; consider translations
      //wBtn.innerHTML = '<img src="https://www.titanpush.com/images/chat/10_icon.svg" width="20" alt="" max-width="20" style="vertical-align: inherit; margin-right: 4px; "> Comprar por whatsapp';
      wBtn.innerHTML = 'Carrito via whatsapp';
      //Override some properties
      wBtn.style.background = _WPN_WA_BACKGROUND_COLOR;
      wBtn.style.float = 'none';
      wBtn.style.boxShadow = 'none';
      wBtn.style.border = 'none';
      wBtn.style.height = 'auto';
      wBtn.style.marginTop = wBtn.style.marginBottom;

      const msg = window['wpnObject'].wabtnmsg;
      wBtnContainer.appendChild(wBtnSeparator);
      wBtnContainer.appendChild(wBtn);

      //Second message, below button
      if(typeof msg === 'string' && msg.length > 0) {
        //This style applies only if text is present
        wBtn.style.marginBottom = '0';
        const wBtnText = document.createElement('div');
        wBtnText.style.margin = '5px 0 15px';
        wBtnText.style.textAlign = 'center';
        wBtnText.style.color = 'black';
        wBtnText.style.fontStyle = '12px';
        wBtnText.innerText = msg; //TODO Need to consider translations
        wBtnContainer.appendChild(wBtnText);
      }

      btn.insertAdjacentElement('afterend', wBtnContainer);
    }
  }
}

function _wpnRedirectBtnToWhatsapp() {
  const btn = document.querySelector(_WPN_BUY_BTN_SELECTOR);
  const $f = $(btn.closest('form'));
  const formData = $f.serializeArray();

  let newParams = {'pid': LS.product.id};
  let buyProdUrl = window.location.href;
  const url = new URL(buyProdUrl);

  formData.forEach((datum, i)=>{
    const variantNumber = _wpnGetVariantParameterNumber(datum.name);
    if(variantNumber !== false){
      newParams['v' + variantNumber] = datum.value;
    }
  })
  const searchParams = new URLSearchParams(url.searchParams);
  searchParams.delete('wappbeta');
  Object.keys(newParams).forEach(key => searchParams.append(key, newParams[key]));

  //Send open event to the tracker
  server.trackEvent(window['wpnObject'].clientId, WAPP_BUTTON_CLICK_EVENT_TYPE, 'click', null, window.location.href, null, 1, null,
    null, null, null);
  //If google analytics is set in the client's site, then fire an event when a click is made to the current link
  browser.sendEventToClientsGA4("Whatsapp Product Button", null, null, null, "wpn_click");
  window.location.href = _wpnBuildWhatsappRedirectButtonUrl(url.origin + '?' + searchParams.toString());
}

function _wpnBuildWhatsappRedirectButtonUrl(productUrl) {
  const phone = atob(window['wpnObject'].wapn);
  const encodedText = encodeURIComponent('Me gustaría comprar el siguiente producto: ' + productUrl);
  return 'https://wa.me/' + phone + '?text=' + encodedText;
}

function _wpnGetVariantParameterNumber(nameStr) {
  const pattern = /variation\[(\d+)]/;
  const result = pattern.exec(nameStr);

  if(result !== null && typeof result[1] !== 'undefined'){
    return result[1];
  }
  return false;
}

const enabledWaStores = [2366020, 1935828, 1022935]; //Add here testing, betas for wa
function _wpnHasWhatsappButtonInstalled() {
  return enabledWaStores.includes(LS.store.id) && window['wpnObject'].wapn;
}
