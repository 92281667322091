import * as browser from "./browser";
import {_wpnGetPageType} from "./product_detection";
import * as ui from "./ui";
import * as server from "./server";

//Experiment information cached for the current client, as JSON
const WPN_ASSIGNED_EXPERIMENTS_KEY = '_wpn_eatouser';

/**
 * Returns an JSON object with the experiments the user is assigned (enrolled or not) to
 * @private
 */
function _wpnGetExperimentsAssigned() {
  var expAssigned = browser._wpnGetCookie(WPN_ASSIGNED_EXPERIMENTS_KEY);

  if (expAssigned === null || expAssigned === "") {
    return {};
  }

  return JSON.parse(expAssigned);
}

/**
 * Saves the experiments the user has been assigned (enrolled or not) to localStorage or cookie when not enabled
 * @param data JSON object
 * @returns {void}
 * @private
 */
function _wpnStoreExperimentsAssigned(data) {
  var jsonData = JSON.stringify(data);
  browser._wpnSetCookie(WPN_ASSIGNED_EXPERIMENTS_KEY, jsonData, 365);
}


/**
 * Returns the current experiments the user is enrolled for or not, as a string comma separated To be used for tracking
 * @private
 */
export function _wpnGetCurrentExperiments() {
  var _wpnAssignedExp = _wpnGetExperimentsAssigned();
  var enrolled = [];
  for (var expName in _wpnAssignedExp) {
    if (_wpnAssignedExp.hasOwnProperty(expName)) {
      if (_wpnAssignedExp[expName] === true) {
        enrolled.push(expName + "_1");
      } else {
        enrolled.push(expName + "_0")
      }
    }
  }

  // Check if the rsults page has results for tiendanube
  if (typeof LS === 'object') {
    // Check if the current URL contains 'search'
    const urlContainsSearch = window.location.href.includes('search');
    // Check if an element with the attributes 'data-product-id' and 'data-product-type="list"' exists in the DOM
    const elementExists = document.querySelector('[data-product-id][data-product-type="list"]') !== null;

    if(urlContainsSearch){
      enrolled.push('search_results_' + (elementExists ? '1' : '0'))
    }
  }

  return enrolled.join(',');
}

/**
 * Checks if the user is assigned (enrolled or not) to a given experiment
 * @param experiment
 * @returns {boolean}
 * @private
 */
export function _wpnCheckExperimentAssigned(experiment, bucketSize) {
  var fullExperimentName = experiment + '_' + bucketSize;
  return _wpnGetExperimentsAssigned().hasOwnProperty(fullExperimentName);
}

export function _wpnIsEnrolledInExperiment(experiment, bucketSize) {
  var fullExperimentName = experiment + '_' + bucketSize;
  var _wpnAssignedExp = _wpnGetExperimentsAssigned();
  return _wpnAssignedExp.hasOwnProperty(fullExperimentName) && _wpnAssignedExp[fullExperimentName] === true;
}

/**
 * Assigns an experiment or not to a used and saves the assignment and value of enrolllment (true or false)
 * @param experiment
 * @param bucketSize
 * @param id
 * @param gaFunction
 * @private
 */
export function _wpnAssignExperiment(experiment, bucketSize, id, gaFunction) {
  var opportunity = _wpnGenerateHashFromId(id) % 100;

  var enrolled = false;
  if (opportunity < bucketSize) { //If bucket size is 60%, from 0 to 59 it should be added as enrolled
    enrolled = true;
  }
  _wpnClearExperimentByPrefix(experiment);
  var current = _wpnGetExperimentsAssigned();

  var fullExperimentName = experiment + '_' + bucketSize;

  if (gaFunction) {
    if (gaFunction === 'gtag') {
      gtag('event', fullExperimentName, {
        'event_category': 'experiment',
        'event_label': enrolled ? 'enabled' : 'disabled',
        'event_value': enrolled ? 1 : 0,
        'non_interaction': true
      });
    } else {
      window[gaFunction]('send', 'event', 'experiment', fullExperimentName, enrolled ? 'enabled' : 'disabled', enrolled ? 1 : 0,
          {nonInteraction: true});
    }
  }

  current[fullExperimentName] = enrolled;
  _wpnStoreExperimentsAssigned(current);
  return enrolled;
}

/**
 * Clear experiment from user that starts with the given prefix
 * @param experimentPrefix {string}
 * @private
 */
export function _wpnClearExperimentByPrefix(experimentPrefix) {
  var alreadyAssigned = _wpnGetExperimentsAssigned();
  var preserve = {};

  for (var key in alreadyAssigned) {
    if (alreadyAssigned.hasOwnProperty(key) && _wpnstrncmp(key, experimentPrefix, experimentPrefix.length) != 0) {
      preserve[key] = alreadyAssigned[key];
    }
  }

  _wpnStoreExperimentsAssigned(preserve);
}

function _wpnstrncmp(str1, str2, n) {
  var stra = str1.substring(0, n);
  var strb = str2.substring(0, n);
  return ((stra == stra) ? 0 :
      ((strb > strb) ? 1 : -1));
}

/**
 * Returns a hash code (numeric) from an ID
 * @param id
 * @returns {number}
 * @private
 */
function _wpnGenerateHashFromId(id) {
  var hash = 0, i, chr;
  for (i = 0; i < id.length; i++) {
    chr = id.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}