import {
    STORE_PROVIDER_TN,
    _WPN_WEB_PAGE_TYPE_THANK_YOU,
    _wpnGetPageType,
} from "./product_detection";

/**
 * Allows to apply groovin Ads pixels, based on the store provider. For now, it will just be applied for tiendanube
 * @param groovinId
 * @param storeProvider
 */
export function applyTrackings(groovinId, storeProvider) {
    if (groovinId) {
        const pageType = _wpnGetPageType();
        if (pageType === _WPN_WEB_PAGE_TYPE_THANK_YOU) {
            applyThankYouTrackings(groovinId, storeProvider);
        } else{
            applyCartTrackings(groovinId, storeProvider);
        }

        applyGlobalTrackings(groovinId, storeProvider);
    }
}

/**
 * Applies the cart tracking pixel
 * @param groovinId
 * @param storeProvider
 */
function applyCartTrackings(groovinId, storeProvider) {
    if (storeProvider === STORE_PROVIDER_TN) {
        document.addEventListener('cart.item.added', function(cartData){ ;
            const id = cartData.detail.item.id;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://ads01.groovinads.com/grv/track/bhpx.os?idc=${groovinId}&fgjs=1&cart=1&sku=${id}`;
            document.head.appendChild(script);
        });
    }
}

/**
 * Applies the thank you tracking pixel
 * @param groovinId
 * @param storeProvider
 */
function applyThankYouTrackings(groovinId, storeProvider) {
    if (storeProvider === STORE_PROVIDER_TN) {
        const orderData = LS.order; //This could be replaced for a generic function within product_detection for example if needed
        if (orderData && orderData.id) {
            const script = document.createElement('script');
            const amount = orderData.total / 100;
            script.type = 'text/javascript';
            script.src = `https://ads01.groovinads.com/grv/track/px.os?fgimg=0&idpixel=15482&goalvalue=${amount}&idtransaction=${orderData.id}`;
            document.head.appendChild(script);
        }
    }
}

/**
 * Applies the global tracking pixel
 * @param groovinId
 * @param storeProvider
 */
function applyGlobalTrackings(groovinId, storeProvider) {
    if (storeProvider === STORE_PROVIDER_TN) {
        const productData = LS.product; //This could be replaced for a generic function within product_detection for example if needed
        let productId = null;
        if (productData && productData.id) {
            productId = productData.id;
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://ads01.groovinads.com/grv/track/bhpx.os?idc=${groovinId}&fgjs=1`;
        if (productId) {
            script.src += `&sku=${productId}`;
        }
        document.head.appendChild(script);
    }
}