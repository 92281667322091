import * as browser from './browser.js';

/**** UI Functions  ***/

/**
 * The dialogAttributes must contain the next body
 * {
 *     'modalBackgroundColor' : ...,
 *     'terms': ...,
 *     'image' : ...,
 *     'title' : ...,
 *     'msg' : ...,
 *     'yesText' : ...,
 *     'noText' : ...,
 *     'modalFontColor' : ...,
 *     'ad': ...
 * }
 */
export function confirmDialog(dialogAttributes) {
  let dialog = document.createElement('div');
  dialog.style.cssText = "box-sizing: border-box; padding: 24px; width: 512px; max-width: 100%; display: block; border-radius: 4px; background-color:" + dialogAttributes.modalBackgroundColor + "; " +
    "display: flex; flex-direction: column; justify-content: space-between;" +
    "box-shadow: 0px 9px 20px rgba(26, 26, 26, 0.25), 0px 3.46667px 6.37037px rgba(26, 26, 26, 0.151852), 0px 0.733333px 1.62963px rgba(26, 26, 26, 0.0981481);";
  dialog.id = 'wpnDialogId';

  if (dialogAttributes.image == null || dialogAttributes.image.length === 0) {
      dialog.style.height = '155px';
  }

  appendToDialog(dialog, createFlexContainer(dialogAttributes));
  appendToDialog(dialog, createDialogButtons(dialogAttributes.yesText, dialogAttributes.noText, dialogAttributes.backgroundColor));

  if (dialogAttributes.terms != null && dialogAttributes.terms.length > 0) {
    appendToDialog(dialog, createTermsLink(dialogAttributes.terms));
  }

  if (dialogAttributes.ad != null && dialogAttributes.ad.length > 0) {
    appendToDialog(dialog, createAdLink(dialogAttributes.ad));
  }

  let body = document.getElementsByTagName('body')[0];
  let dialogContainer = createDialogContainer(19000, 'top');

  dialogContainer.appendChild(dialog);
  body.appendChild(dialogContainer);

  let reminder = document.getElementById('wpnReminder');
  if (reminder != null) {
    reminder.remove();
  }
}

/**
 * The dialogAttributes must contain the next body
 * {
 *     'modalBackgroundColor' : ...,
 *     'terms': ...,
 *     'image' : ...,
 *     'title' : ...,
 *     'msg' : ...,
 *     'yesText' : ...,
 *     'noText' : ...,
 *     'modalFontColor' : ...,
 *     'ad': ...
 * }
 */
export function confirmDialogMobile(dialogAttributes) {
  let dialog = document.createElement('div');
  let windowWidth = 410;
  if (window.innerWidth < windowWidth) {
    windowWidth = window.innerWidth;
  }

  dialog.style.cssText = "box-sizing: border-box; width: " + windowWidth + "px; max-width: 100%; height: 272px; padding: 40px 24px 24px 24px; background-color:" + dialogAttributes.modalBackgroundColor + "; display: block; border-radius: 4px; " +
    "display: flex; flex-direction: column; justify-content: space-between;" +
    "box-shadow: 0px -8px 20px rgba(26, 26, 26, 0.25), 0px -3.47px 6.37037px rgba(26, 26, 26, 0.151852), 0px -0.73px 1.62963px rgba(26, 26, 26, 0.0981481);";
  dialog.id = 'wpnDialogId';

  appendToDialog(dialog, createFlexContainerMobile(dialogAttributes));
  appendToDialog(dialog, createDialogButtonsMobile(dialogAttributes.yesText, dialogAttributes.noText, dialogAttributes.backgroundColor));

  if (dialogAttributes.terms != null && dialogAttributes.terms.length > 0) {
    appendToDialog(dialog, createTermsLinkMobile(dialogAttributes.terms));
  }

  if (dialogAttributes.ad != null && dialogAttributes.ad.length > 0) {
    appendToDialog(dialog, createAdLinkMobile(dialogAttributes.ad));
  }

  let body = document.getElementsByTagName('body')[0];
  let dialogContainer = createDialogContainer(19000, 'bottom');

  dialogContainer.appendChild(dialog);
  body.appendChild(dialogContainer);

  let reminder = document.getElementById('wpnReminder');
  if (reminder != null) {
    reminder.remove();
  }
}

export function confirmDialogHtml(html) {
  let dialog = document.createElement('div');
  dialog.id = 'wpnDialogcontainer';
  dialog.innerHTML = html;
  let body = document.getElementsByTagName('body')[0];
  body.appendChild(dialog);

  let reminder = document.getElementById('wpnReminder');
  if (reminder != null) {
    reminder.remove();
  }
}

export function colorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = "#", c, i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }
  return rgb;
}

export function dialogShouldBeShown(currentPageViewAmount, minPageViews, recurringEvery, minDaysBetween) {
  if (currentPageViewAmount >= minPageViews) {
    if (currentPageViewAmount == minPageViews ||
      (recurringEvery > 0 && ((currentPageViewAmount - minPageViews) % recurringEvery == 0))) {
      if (minDaysBetween > 0) {
        let lastDateShow = browser._wpnGetCookie('wpnLastDenial');
        if (lastDateShow == null || lastDateShow === "") {
          lastDateShow = 0;
        }

        return (Date.now() - parseInt(lastDateShow)) / (1000 * 3600 * 24) > minDaysBetween;
      }

      return true;
    }
  }
  return false;
}

export function rgbToHex(rgbString) {
  if (rgbString) {
    let parts = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    delete (parts[0]);
    for (let i = 1; i <= 3; ++i) {
      parts[i] = parseInt(parts[i]).toString(16);
      if (parts[i].length == 1) parts[i] = '0' + parts[i];
    }
    return '#' + parts.join('').toUpperCase(); // "#0070FF"
  }
}


// Auxiliar functions

function createDialogContainer(zIndex, fixOn) {
  fixOn = fixOn || 'top';

  let dialogContainer = document.createElement('div');
  dialogContainer.id = 'wpnDialogcontainer';
  dialogContainer.style.cssText = 'font-family: Roboto, Helvetica, Tahoma; display: inline-block; ' + fixOn + ': 0px; z-index: ' + (zIndex + 1) + ';position: fixed;left: 50%; transform: translateX(-50%); letter-spacing: normal;';

  return dialogContainer;
}


function createDialogTitle(title, color, isMobile = false) {
  let dialogTitle = document.createElement('div');
  let deviceStyles = isMobile ? 'line-height: 18px; margin: 0 0 4px 0;' : 'margin: 4px 0;';

  dialogTitle.style.cssText = 'font-size: 16px; text-align: left; color:' + color + '; font-weight: bold; display:block;' + deviceStyles;
  dialogTitle.innerHTML = title;
  return dialogTitle;
}

function createDialogText(text, color, isMobile = false) {
  let dialogText = document.createElement('div');
  let deviceStyles = isMobile ? 'line-height: 18px;' : 'line-height: 21px';

  dialogText.style.cssText = 'font-size: 14px; text-align: left; color:' + color + '; font-weight:normal; display:block;' + deviceStyles; //filter: contrast(0.4)?
  dialogText.innerHTML = text;
  return dialogText;
}


function createTextContainer(title, text, textColor, isMobile = false) {
  let textContainer = document.createElement('div');

  if (title.length > 0) {
    textContainer.appendChild(createDialogTitle(title, textColor, isMobile))
  }

  if (text.length > 0) {
    textContainer.appendChild(createDialogText(text, textColor, isMobile))
  }

  return textContainer;
}

function createDialogBtn(text, styles, id) {
  let btn = document.createElement('a');
  btn.innerHTML = text;
  btn.id = id;
  btn.style.cssText = styles;
  return btn;
}

function createDialogButtons(yesText, noText, backgroundColor) {
  let dialogButtons = document.createElement('div');

  dialogButtons.style.cssText = 'text-align: right; margin-top: 16px;margin-bottom: 16px; position: relative; overflow: visible;';

  backgroundColor = backgroundColor || '#4d90fe';

  let commonStyles = "cursor: pointer; text-align: center; display: inline-block; padding: 7px 20px; font-weight: 600; line-height: 21px; border-radius: 8px;";

  let noStyles = commonStyles + "margin-right: 8px; background: transparent; font-size: 13px; text-decoration:none; color: " + backgroundColor + "!important; border: 1px solid " + backgroundColor + "!important;";
  dialogButtons.appendChild(createDialogBtn(noText, noStyles, 'btnNoIdWpnPush'));

  let yesStyles = commonStyles + "background: " + backgroundColor + "!important; color: rgb(255, 255, 255) !important; font-size: 14px; text-decoration:none;";
  dialogButtons.appendChild(createDialogBtn(yesText, yesStyles, 'btnYesIdWpnPush'));

  return dialogButtons;
}

function createDialogButtonsMobile(yesText, noText, backgroundColor) {
  let dialogButtons = document.createElement('div');

  backgroundColor = backgroundColor || '#0049B2';

  let commonStyles = "cursor: pointer; text-align: center; display: block; padding: 10px; font-size: 14px; font-weight: 600; line-height: 14px; text-decoration:none; border-radius: 8px;";

  let yesStyles = commonStyles + "margin-bottom: 16px; background:" + backgroundColor + "; color: rgb(255, 255, 255)!important; text-decoration:none; ";
  dialogButtons.appendChild(createDialogBtn(yesText, yesStyles, 'btnYesIdWpnPush'));

  let noStyles = commonStyles + "background: transparent; color:" + backgroundColor + "!important; border: 1px solid " + backgroundColor + "!important";
  dialogButtons.appendChild(createDialogBtn(noText, noStyles, 'btnNoIdWpnPush'));

  return dialogButtons;
}

function createAdLink(ad) {
  let adContainer = document.createElement('div');
  adContainer.innerHTML = ad;
  adContainer.style.cssText = "text-align: center; position: absolute; bottom: 9px; left: 13px; line-height: 10px;";
  return adContainer;
}

function createAdLinkMobile(ad) {
  let adContainer = document.createElement('div');
  adContainer.innerHTML = ad;
  adContainer.style.cssText = "text-align: center; position: absolute; top: 10px; right: 25px; line-height: 10px;";
  return adContainer;
}

function createTermsLink(terms) {
  if (terms != null) {
    let container = document.createElement('div');
    container.innerHTML = terms;
    container.style.cssText = "font-size: 11px; line-height: 10px; position: absolute; bottom: 8px; right: 24px;";
    return container;
  }
  return null;
}

function createTermsLinkMobile(terms) {
  if (terms != null) {
    let container = document.createElement('div');
    container.innerHTML = terms;
    container.style.cssText = "font-size: 11px; line-height: 10px; position: absolute; top: 11px; left: 24px;";
    return container;
  }
  return null;
}

function createDialogImage(image, size) {
  size = size || 96;

  let imageContainer = document.createElement('div');
  imageContainer.style.cssText = 'max-width:' + size + 'px; height: ' + size + 'px; margin-right: 16px;  overflow: hidden;';
  if (image != null && image.length > 0) {
    let imageImg = document.createElement('img');
    imageImg.style.cssText = "max-width:" + size + "px; position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%)";
    imageImg.src = image;
    imageImg.width = size;
    imageContainer.appendChild(imageImg);
  }
  return imageContainer;
}

/**
 * The dialogAttributes must contain the next body
 * {
 *     'modalBackgroundColor' : ...,
 *     'image' : ...,
 *     'title' : ...,
 *     'msg' : ...,
 *     'yesText' : ...,
 *     'noText' : ...,
 *     'modalFontColor' : ...,
 *     'ad': ...
 * }
 */
function createFlexContainer(flexContainerAttributes) {
  let flexDiv = document.createElement('div');
  flexDiv.style.cssText = "display: flex; height: 100%;box-sizing: border-box;";

  let imgFlex = document.createElement('div');
  imgFlex.style.cssText = "padding: 0; text-align: center; flex: 0 0 auto;";
  let hasImage = !(flexContainerAttributes.image == null || flexContainerAttributes.image.length === 0);
  imgFlex.appendChild(createDialogImage(flexContainerAttributes.image, hasImage ? 96 : 1));

  let textFlex = document.createElement('div');
  textFlex.style.cssText = "align-items: center;padding: 0; display: inline-block; flex: 1 1 auto; display: flex; flex-direction: row; justify-content: space-between";

  if (hasImage) {
    textFlex.style.height = '96px';
  }

  textFlex.appendChild(createTextContainer(flexContainerAttributes.title, flexContainerAttributes.msg, flexContainerAttributes.modalFontColor));

  flexDiv.appendChild(imgFlex);
  flexDiv.appendChild(textFlex);

  return flexDiv;
}

/**
 * The dialogAttributes must contain the next body
 * {
 *     'modalBackgroundColor' : ...,
 *     'image' : ...,
 *     'title' : ...,
 *     'msg' : ...,
 *     'yesText' : ...,
 *     'noText' : ...,
 *     'modalFontColor' : ...,
 *     'ad': ...
 * }
 */
function createFlexContainerMobile(flexContainerAttributes) {
  let flexDiv = document.createElement('div');
  flexDiv.style.cssText = "display: flex; height: 100%;";

  let imgFlex = document.createElement('div');
  imgFlex.style.cssText = "padding: 0; text-align: center; flex: 0 0 auto;";
  imgFlex.appendChild(createDialogImage(flexContainerAttributes.image, 96));

  let textFlex = document.createElement('div');
  textFlex.style.cssText = "padding: 0; display: inline-block; flex: 1 1 auto; display: flex; flex-direction: column; justify-content: space-between";
  textFlex.appendChild(createTextContainer(flexContainerAttributes.title, flexContainerAttributes.msg, flexContainerAttributes.modalFontColor, true));

  flexDiv.appendChild(imgFlex);
  flexDiv.appendChild(textFlex);

  return flexDiv;
}

function isElement(o) {
  return (
    typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
      o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
  );
}

function appendToDialog(dialog, newElement) {
  if (isElement(newElement)) {
    dialog.appendChild(newElement);
  }
}

// Closes and remove an element by id, optionally adding a helper fading class
export function closeAndRemove(id, fadingClass = "wpn_fade") {
  const element = document.getElementById(id);
  if (element != null) {
    if (fadingClass) {
      element.classList.add(fadingClass);
    }
    element.remove();
  }
}

//Flowy bubble selector
const FLOWY_BUBBLE_SELECTOR = '.flowy-whatsapp-bubble';
//Flowy class to indicate the bubble is on the left
const FLOWY_BUBBLE_LEFT_CLASS = 'flowy-position-left';
//TITANPush bubble selector, for checking overlapping, in this case with flowy
const TITAN_BUBBLE_GENERIC_SELECTOR_FOR_FLOWY_OVERLAPPING = '.wpn-bubble-widget';
//TITANPush bubble selector, with right position, for checking overlapping, in this case with flowy
const TITAN_BUBBLE_RIGHT_SELECTOR_FOR_FLOWY_OVERLAPPING = '.wpn-position-right';
//TITANPush bubble selector, with left position, for checking overlapping, in this case with flowy
const TITAN_BUBBLE_LEFT_SELECTOR_FOR_FLOWY_OVERLAPPING = '.wpn-position-left';
/*
 * Where should overlapping bubbles be moved to (left/right - depending on the overlapped position) if a collision with
 * Flowy's floating widget is found
 */
const POSITION_TO_MOVE_OVERLAPPING_BUBBLES_WITH_FLOWY = '110px';

//Checks overlapping bubbles with other partners (flowy, in this case)
export function checkFlowyOverlappingBubbles() {
  //Flowy chat
  const flowyBubble = document.querySelector(FLOWY_BUBBLE_SELECTOR);
  if (flowyBubble !== null) {
    //Check the position first and get our matching elements
    let extraTitanClass = TITAN_BUBBLE_RIGHT_SELECTOR_FOR_FLOWY_OVERLAPPING;
    let positionStyle = 'right';
    if (flowyBubble.classList.contains(FLOWY_BUBBLE_LEFT_CLASS)) { //We assume default is right
      extraTitanClass = TITAN_BUBBLE_LEFT_SELECTOR_FOR_FLOWY_OVERLAPPING;
      positionStyle = 'left';
    }
    const titanOverlappingBubbles = document.querySelectorAll(TITAN_BUBBLE_GENERIC_SELECTOR_FOR_FLOWY_OVERLAPPING + extraTitanClass);

    Array.from(titanOverlappingBubbles).forEach((element) => {
      element.style[positionStyle] = POSITION_TO_MOVE_OVERLAPPING_BUBBLES_WITH_FLOWY;
    });
  }
}