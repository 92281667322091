import * as browser from "./browser";

var WPN_BASE_URL = process.env.BASE_URL;
var WPN_TRACKER_BASE_URL = process.env.TRACKER_BASE_URL;
const WPN_API_URL = process.env.API_URL;
const NEW_COOKIE_NAME = 'TPIDC';
const LEGACY_COOKIE_NAME = 'titanPushid';

export function wpnSetBaseUrl(url) {
    WPN_BASE_URL = url
}

export function wpnSetTrackerBaseUrl(url) {
    WPN_TRACKER_BASE_URL = url
}

export function wpnBaseUrl() {
    return WPN_BASE_URL;
}

export function wpnTrackerBaseUrl() {
    return WPN_TRACKER_BASE_URL;
}

/**** Tracking Functions  ****/

//Tracks event by sending a post to the WPN tracker service //TODO: Maybe default more parameters to null to avoid sending them
export function trackEvent(clientId, eventType, event, title, url, value, pageType, canonicalUrl, referrer, experiments, otherId, label = null, queryString = '') {
    if (url === undefined || url === null) {
        url = window.location;
    }

    if (urlShouldBeIgnoredForTracking(url)) {
        return;
    }

    url = encodeURIComponent(url);

    if (title === undefined || title === null) {
        title = document.title.substr(0, 250);
    }

    let data = "&eventtype=" + eventType + "&idclient=" + clientId + "&event=" + event + "&value=" + value + "&url=" + url +
      "&pagetitle=" + encodeURIComponent(title) + "&pagetype=" + pageType +
      "&devicetype=" + browser._wpnGetDeviceType();

    if (label !== null) {
        data += "&label=" + label;
    }

    if (referrer !== "" && referrer !== null) {
        data += "&referrer=" + encodeURIComponent(referrer);
    }

    if (canonicalUrl !== null) {
        data += "&canonicalurl=" + encodeURIComponent(canonicalUrl);
    }

    if (experiments !== "" && experiments !== null) {
        data += "&experiments=" + experiments;
    }

    if (otherId !== null) {
        data += "&otherid=" + otherId;
    }

    return sendToTrackerWithId('/' + queryString, data);
}

//Checks if the given URL should be ignored for tracking or not
function urlShouldBeIgnoredForTracking(url) {
    const ignoreRegexes = [
        /\.myvtex\.com/
    ];

    return ignoreRegexes.some(function(rx) { return rx.test(url); });
}


//Send a request to WPN's tracker.
function sendToTrackerWithId(endpoint, data) {
    return recoverId().then(function (myId) {
        //Append the retrieved subscriptor id and timestamp
        data = "subscriptorid=" + myId + data + "&timestamp=" + Math.round(Date.now() / 1000);
        sendToTracker(endpoint, data);
    });
}

//Send the payload identified with data to the tracker specified endpoint
export function sendToTracker(endpoint, data) {
    //Must pass a blob in order to change the beacons default content type for the tracker. Tracker accepts this format.
    let form = new Blob([data], {type: "application/x-www-form-urlencoded"});
    if (!navigator.sendBeacon) return;
    navigator.sendBeacon(wpnTrackerBaseUrl() + endpoint, form);
}

/**** End tracking Functions  ****/

//Restores the id from cookie/local storage in order to identify the current user
export function recoverId() {
    //Try to return new ID
    let id = browser._wpnGetCookie(NEW_COOKIE_NAME);
    if (id == null || id === "") {
        if (browser.storageAvailable('localStorage')) {
            id = localStorage.getItem(NEW_COOKIE_NAME);
            if (id != null) {
                browser._wpnSetCookie(NEW_COOKIE_NAME, id, 15);
            }
        }
    }

    if (id == null || id == undefined || id == 'undefined' || id === "") {
        //If not present, check if legacy ID exists
        let legacyId = browser._wpnGetCookie(LEGACY_COOKIE_NAME);
        if (legacyId == null || legacyId === "") {
            if (browser.storageAvailable('localStorage')) {
                legacyId = localStorage.getItem(LEGACY_COOKIE_NAME);
            }
        }

        let data = "&legacyId=" + legacyId + "&clientId=" + window['wpnObject'].clientId + "&cookieDomain=" + wpnBaseUrl() + "&device=" + browser._wpnGetDeviceType();
        //Generate ID considering legacy ID
        return fetch(wpnBaseUrl() + '/push-register/generate-id', {
            method: 'POST',
            mode: 'cors', //To avoid opaque and empty responses
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            credentials: 'include',
            body: data
        }).then(function (response) {
            return response.json().then(function (dataValue) {
                return dataValue.id;
            });
        }).then(resolveIdFetchPromise);
    }

    return new Promise(function (resolve, reject) {
        let id = browser._wpnGetCookie(NEW_COOKIE_NAME);
        resolve(id);
    });
}

function resolveIdFetchPromise(id) {
    browser._wpnSetCookie(NEW_COOKIE_NAME, id, 365 * 5);
    browser._wpnDeleteCookie(LEGACY_COOKIE_NAME);
    if (browser.storageAvailable('localStorage')) {
        localStorage.setItem(NEW_COOKIE_NAME, id);
        localStorage.removeItem(LEGACY_COOKIE_NAME);
    }
    return id;
}

//TODO: Quizás habría que refactorizar estos métodos por algo más sencillo y claro y menos repetitivo.

//TODO: Parametrizarle el base URL para poder llamar a la api, no?
//Send a request (POST) to WPN service.
export function sendToWPN(endpoint, data) {
    return recoverId().then(function (myId) {
        data = "id=" + myId + data;

        let headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        return fetch(wpnBaseUrl() + endpoint, {
            method: 'POST',
            mode: 'cors', //To avoid opaque and empty responses
            headers: headers,
            body: data
        }).then(function (response) {
            return response.json();
        }).catch(function (err) {
        });
    });
}

//TODO: Parametrizarle el base URL para poder llamar a la API, no?
//Sends a request (GET) to WPN Service
export function retrieveFromWPN(endpoint, query, referrerPolicy = null, headers = null) {
    let fetchOptions = {
        method: 'GET',
        mode: 'cors', //To avoid opaque and empty responses
    };

    if (referrerPolicy !== null) {
        fetchOptions.referrerPolicy = referrerPolicy; //otherwise, defaults to the browsers default policy
    }

    if (headers !== null) {
        fetchOptions.headers = headers;
    }

    endpoint = wpnBaseUrl() + endpoint + '?' + query;
    let request = new Request(endpoint, fetchOptions);
    return fetch(request).then(function (response) {
        return response.json();
    }).catch(function (err) {
    });
}