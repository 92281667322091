const translations = {
    'es': {
        'seconds': 'segundos',
        'minutes': 'minutos',
        'hours': 'horas',
        'days': 'días',
        'second': 'segundo',
        'minute': 'minuto',
        'hour': 'hora',
        'day': 'día',
        'History': 'Historial',
        'Last Visit': 'Última visita',
        'Most Popular': 'Más populares',
        'Recommended Items': 'Productos recomendados',
        'carrousel': 'carrusel'
    },
    'pt': {
        'seconds': 'segundos',
        'minutes': 'minutos',
        'hours': 'horas',
        'days': 'dias',
        'second': 'segundo',
        'minute': 'minuto',
        'hour': 'hora',
        'day': 'día',
        'History': 'Historia',
        'Last Visit': 'Último visto',
        'Most Popular': 'Mais populares',
        'Recommended Items': 'Itens Recomendados',
        'carrousel': 'carrossel'
    },
}

const SUBSCRIPTION_DATA_CACHE = 'sdtp';

//Get the configuration from localStorage, if available
function _wpnGetSubscriptionCachedDataFromStorage() {
    if (typeof window.localStorage !== "undefined") {
        let conf = window.localStorage.getItem(SUBSCRIPTION_DATA_CACHE);
        if (conf !== null) {
            return JSON.parse(conf);
        }
    }
    return {};
}

//Get the language code to use
function _wpnGetLanguageCode() {
    let language = _wpnGetConfigOption('language');

    if (language !== null && language !== "") {
        return language;
    }
    return navigator.language.substr(0, 2); //Get browser language;
}

//Get the country code to use
function _wpnGetCountryCode() {
    let country = _wpnGetConfigOption('country');

    if (country !== null) {
        return country;
    }
    return false; //Do not try to guess;
}


//Get the currency code to use
function _wpnGetCurrencyCode() {
    let cCode = _wpnGetConfigOption('currencyCode');
    if (cCode !== null) {
        return cCode;
    }
    return 'USD'; //USD should be de default currency as the short notation will be replaced by '$' which is understood everywhere
}

//Try to get the confirguration given from the localStorage or wpnObject
function _wpnGetConfigOption(configOption) {
    if (typeof window['wpnObject'] !== "undefined" && typeof window['wpnObject'][configOption] !== "undefined" &&
        window['wpnObject'][configOption] !== null) {
        return window['wpnObject'][configOption];
    } else {
        let conf = _wpnGetSubscriptionCachedDataFromStorage();

        if (typeof conf[configOption] !== "undefined") {
            return conf[configOption];
        }
    }
    return null;
}

//Translate the given text with the tables configured
export function _wpnTranslate(textToTranslate) {
    let language = _wpnGetLanguageCode();
    if (typeof translations[language] !== "undefined" && typeof translations[language][textToTranslate] !== "undefined") {
        return translations[language][textToTranslate];
    }

    return textToTranslate;
}

function isFromArgentina(countryCode) {
    return countryCode.toLowerCase() === 'ar'
}

//Format number to current locale, as currency
export function _wpnFormatNumberAsCurrency(number, forceCurrency = null) {
    if (typeof Intl === "object") {
        const countryCode = _wpnGetCountryCode();
        let currencyDisplay = 'narrowSymbol';
        let code = _wpnGetLanguageCode();
        let currencyCode = _wpnGetCurrencyCode();
        if (forceCurrency != null && forceCurrency !== "" && forceCurrency !== currencyCode) {
            currencyCode = forceCurrency;
            currencyDisplay = 'code';
        }

        if (countryCode !== false) {
            code += "-";
            code += countryCode;
        }

        try {
            return new Intl.NumberFormat(code, {
                style: 'currency', currencyDisplay: currencyDisplay,
                currency: currencyCode, maximumFractionDigits: isFromArgentina(countryCode) ? 0 : 2
            }).format(number);
        } catch (error) { //For safari, see: https://github.com/mdn/browser-compat-data/issues/8985
            if (error instanceof RangeError) {
                return new Intl.NumberFormat(code, {
                    style: 'currency', currencyDisplay: 'symbol',
                    currency: currencyCode,
                    maximumFractionDigits: isFromArgentina(countryCode) ? 0 : 2
                }).format(number);
            }
        }
    }
    return number;
}